import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CompContact from '../components/comp-contact'

const Contact = () => {

  return (
    <Layout>

      <SEO title="Contact" />

      <CompContact />

    </Layout>
  )
}

export default Contact